import React from "react"
import { useSelector } from "react-redux"
import Filter from "../components/Filter"
import FilterButton from "../components/FilterButton"
import Layout from "../components/Layout"
import SearchResults from "../components/SearchResults"
import { State } from "../state/store"
import { Props } from "../utils/context"

export default function Search({ pageContext }: Props) {
  const mode = useSelector((state: State) => state.mode)

  return (
    <Layout lang={pageContext.lang}>
      {mode === "FILTER" ? (
        <div className="pt-8">
          <Filter lang={pageContext.lang} />
        </div>
      ) : (
        <SearchResults lang={pageContext.lang} />
      )}

      {mode === "RESULTS" && <FilterButton lang={pageContext.lang} />}
    </Layout>
  )
}
