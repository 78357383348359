import { ListServiceProvidersQuery } from "../../graphql-types"
import { ServiceProvider, ServiceProviderFromQuery } from "../types"
import { Lang, simpleLang } from "../utils/i18n"

type QueryResult = ListServiceProvidersQuery

export function getServiceProvidersFromQueryResult(
  queryResult: QueryResult,
  lang: Lang
) {
  const serviceProviders: ServiceProvider[] = (queryResult.prismic
    .allServiceproviders.edges as ServiceProviderFromQuery[])
    .filter(
      serviceProvider => simpleLang(serviceProvider.node._meta.lang) === lang
    )
    .map(serviceProvider => ({
      type: "serviceprovider",
      id: serviceProvider.node._meta.id,
      uid: serviceProvider.node._meta.uid as string,
      lang: serviceProvider.node._meta.lang,
      name: serviceProvider.node.name[0].text,
      description: serviceProvider.node.description,
    }))

  return serviceProviders
}
