import { graphql, useStaticQuery } from "gatsby"
import Prismic from "prismic-javascript"
import { Document } from "prismic-javascript/types/documents"
import { Dispatch, useEffect, useState } from "react"
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { useDispatch, useSelector } from "react-redux"
import { CategoriesAndServiceProvidersForSearchResultsQuery } from "../../graphql-types"
import { FetchMode, ServiceActions } from "../actions"
import { getCategoriesFromQueryResult } from "../queries/categories"
import { getServiceProvidersFromQueryResult } from "../queries/serviceProviders"
import { State } from "../state/store"
import { Category, Service } from "../types"
import i18n, { complexLang, Lang } from "../utils/i18n"
import ServiceList from "./ServiceList"

export const categoryQuery = graphql`
  query CategoriesAndServiceProvidersForSearchResults {
    prismic {
      allActivitys(first: 100) {
        edges {
          node {
            name
            _meta {
              id
              uid
              lang
            }
          }
        }
      }
      allTravellerprofiles(first: 100) {
        edges {
          node {
            name
            _meta {
              id
              uid
              lang
            }
          }
        }
      }
      allServiceproviders(first: 100) {
        edges {
          node {
            name
            description
            _meta {
              id
              type
              lang
              uid
            }
          }
        }
      }
    }
  }
`

function SelectedCategories(props: { categories: Category[] }) {
  const { categories } = props

  return (
    <h1>
      {categories.length > 0
        ? categories.map(category => `${category.name}.`).join(" ")
        : "Visit Teisko"}
    </h1>
  )
}

function prng(seed: number) {
  return function () {
    var t = (seed += 0x6d2b79f5)
    t = Math.imul(t ^ (t >>> 15), t | 1)
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61)
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296
  }
}

function shuffle<T>(a: T[]): T[] {
  let seed = localStorage.getItem("seed")
  if (!seed) {
    seed = Math.floor(Math.random() * Number.MAX_VALUE) + ""
    localStorage.setItem("seed", seed)
  }

  const rand = prng(parseInt(seed!, 10))

  var j, x, i
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(rand() * (i + 1))
    x = a[i]
    a[i] = a[j]
    a[j] = x
  }
  return a
}

type Props = {
  lang: Lang
}

export default function SearchResults(props: Props) {
  const categoriesAndServiceProvidersResult = useStaticQuery(
    categoryQuery
  ) as CategoriesAndServiceProvidersForSearchResultsQuery
  const categories = getCategoriesFromQueryResult(
    categoriesAndServiceProvidersResult,
    props.lang
  )

  const serviceProviders = getServiceProvidersFromQueryResult(
    categoriesAndServiceProvidersResult,
    props.lang
  )

  const selectedActivities = useSelector(
    (state: State) => state.filter.selectedActivities
  )

  const selectedProfiles = useSelector(
    (state: State) => state.filter.selectedProfiles
  )

  const freeText = useSelector((state: State) => state.filter.freeText)

  const services = useSelector((state: State) => state.services)

  const isDirty = useSelector((state: State) => state.isDirty)
  const totalResultsSize = useSelector((state: State) => state.totalResultsSize)
  const hasMoreResults = useSelector((state: State) => state.hasMoreResults)

  const [isLoadingAll, setIsLoadingAll] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const servicesDispatch = useDispatch<Dispatch<ServiceActions>>()

  const fetchResults = async (mode: FetchMode) => {
    const pageSize = 100

    if (mode === FetchMode.FromBeginning) {
      setIsLoadingAll(true)
    } else {
      setIsLoadingMore(true)
    }

    const api = await Prismic.api("https://visitteisko.prismic.io/api")

    const query = [Prismic.Predicates.at("document.type", "service")]

    if (selectedActivities.length > 0) {
      query.push(
        Prismic.Predicates.any(
          "my.service.activities.activity",
          selectedActivities.map(activity => activity.id)
        )
      )
    }

    if (selectedProfiles.length > 0) {
      query.push(
        Prismic.Predicates.any(
          "my.service.profiles.profile",
          selectedProfiles.map(profile => profile.id)
        )
      )
    }

    if (freeText) {
      query.push(Prismic.Predicates.fulltext("document", freeText))
    }

    const result = await api.query(query, {
      lang: complexLang(props.lang),
      orderings: freeText ? "" : "[document.last_publication_date desc]",
      pageSize,
      page:
        mode === FetchMode.FromBeginning
          ? 1
          : Math.floor(services.length / pageSize) + 1,
    })

    const newServices: Service[] = result.results.map((doc: Document) => ({
      type: "service",
      uid: doc.uid as string,
      lang: doc.lang!,
      title: doc.data.service.name.value[0]?.text as string,
      activities: doc.data.service.activities.value
        .filter((activity: any) => !!activity.activity)
        .map((activity: any) => ({
          id: activity.activity.value.document.id,
          name: categories.activities.find(
            act => act.id === activity.activity.value.document.id
          )?.name,
        })),
      profiles: doc.data.service.profiles.value
        .filter((profile: any) => !!profile.profile)
        .map((profile: any) => ({
          id: profile.profile.value.document.id,
          name: categories.profiles.find(
            prof => prof.id === profile.profile.value.document.id
          )?.name,
        })),
      image: {
        url: doc.data.service.image?.value.main.url,
      },
      serviceProvider: serviceProviders.find(
        provider =>
          provider.id === doc.data.service.serviceprovider?.value.document.id
      ),
    }))

    const totalResultsSize = result.total_results_size
    const currentResultsSize =
      (result.page - 1) * result.results_per_page + result.results_size

    // TODO: Maintain order for the user i.e. seed the PRNG
    if (!freeText) {
      shuffle(newServices)
    }

    servicesDispatch({
      type: "SERVICES_SET",
      services: newServices,
      totalResultsSize,
      currentResultsSize,
      mode,
    })

    setIsLoadingAll(false)
    setIsLoadingMore(false)
  }

  useEffect(() => {
    if (isDirty) {
      fetchResults(FetchMode.FromBeginning)
    }
  }, [isDirty])

  return (
    <div className="m-4">
      <SelectedCategories
        categories={[...selectedActivities, ...selectedProfiles]}
      />
      <div>
        {isLoadingAll ? (
          <div className="flex justify-center pt-8">
            <Loader type="ThreeDots" color="#F7CB58" height={100} width={100} />
          </div>
        ) : (
          <div>
            <div className="text-sm">
              {i18n[props.lang].filter.foundResults(totalResultsSize)}
            </div>
            <ServiceList services={services} lang={props.lang} />
            {hasMoreResults && (
              <div className="flex justify-center pt-8">
                {isLoadingMore ? (
                  <Loader
                    type="ThreeDots"
                    color="#F7CB58"
                    height={100}
                    width={100}
                  />
                ) : (
                  <button
                    className="rounded-full px-8 py-2 cursor-pointer bg-gradient-to-r from-dawnred to-sunyellow"
                    onClick={() => fetchResults(FetchMode.Continue)}
                  >
                    {i18n[props.lang].filter.loadMore}
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
